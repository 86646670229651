
require('./bootstrap');
require('./pages/publications');
require('./pages/orders');
require('./pages/packages');
require('./pages/hubspot');
////require('./pages/upload');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

window.fix_data_tables = function($tables) {
    $($tables).each(function () {
        var datatable = $(this);

        let all_filters = {
            'sales' : {
                'pending': '<span class="badge bg-dark">PENDING</span>',
                'confirmed': '<span class="badge bg-primary">CONFIRMED</span>',
                'invoiced': '<span class="badge badge-outline bg-xero">INVOICED</span>'
            },
            'accounts' : {
                'enabled': '<span class="badge bg-primary">ENABLED</span>',
                'archived': '<span class="badge bg-danger">ARCHIVED</span>',
            }
        };

        let filters = all_filters.sales;
        if ($(this).data('fo') && all_filters[$(this).data('fo')]) {

            filters = all_filters[$(this).data('fo')];

        }

        // SEARCH - Add the placeholder for Search and Turn this into in-line form control
        var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');

        search_input.attr('placeholder', 'Search');
        search_input.removeClass('form-control-sm');
        // LENGTH - Inline-Form control
        var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
        length_sel.removeClass('form-control-sm').removeClass('form-control').addClass('form-select-sm').addClass('form-select');

        datatable.closest('.dataTables_wrapper').addClass('mb-4');
        datatable.closest('.dataTables_wrapper').find('.row:eq(0)').addClass('mb-4');

        let filter_output = '';
        if (!datatable.closest('table').hasClass('no-filter')) {
            for (const [key, value] of Object.entries(filters)) {
                filter_output += value;
            }
        } else {
            filter_output = filters[datatable.closest('table').data('filter')];
        }

        if (filter_output) {
            datatable.closest('.dataTables_wrapper').find('.col-md-6:eq(0)').append('<div class="status-filters"><span>Filters:</span>' + filter_output + '</div>');
        }

    });
}

window.getContract = function(contract, tab) {
    $.ajax({
        url: '/engagements/contract/' + contract,
        type: 'get',
        dataType: 'json',
        data: {},
        success: function (result) {

            $('#contractModal .alert').remove();

            $('#contractModal .modal-title').html(result.contract_name);
            $('#contractModal .modal-body').html(result.contract);

            if (tab) {
                $(tab)[0].click();
            }

            $('#contractModal').modal('show');

            feather.replace();
        }
    });
}

String.prototype.escapeSpecialChars = function () {
    return this.replace(/\n/g, "\n")
        .replace(/"/g, '\"')
        .replace(/\r/g, "\\r")
        .replace(/\t/g, "\\t")
        .replace(/\b/g, "\\b")
        .replace(/\f/g, "\\f");
};

window.number_format = function (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

$(function () {

    /* Month Popup Handler */
    let month_target = null;

    if ($('.log-output').length > 0) {
        $('.log-output').animate({ scrollTop: $('.log-output').prop("scrollHeight")}, 250);
    }

    if ($('.upgrade-order').length > 0) {

        $('#convertExternalModal .modal-body')[0].reset();
        $('#convertExternalModal .account-select').val('').trigger('change');
        $('#convertExternalModal input').val('');
        $('#convertExternalModal .provided-info').html('');

        const provided = JSON.parse($('.upgrade-order').val());

        let output = '';
        Object.entries(provided.contact).forEach(([key, value]) => {
            output += `<label class="col-sm-2"><strong class="tx-12">${key.replace('_', ' ')}</strong></label>
            <div class="col-sm-4">
                <span class="tx-12">${value}</span>
            </div>`;
        });

        $('.provided-info').html(output);

        $('#convertExternalModal').modal({
            keyboard: false,
            backdrop: 'static',
        }).modal('show');

        $('.handle-upgrade-order').on('click', function() {

            let method = $(this).val();
            let account = $('#convertExternalModal .account-select').val();

            if (account === '' && method === 'upgrade') {
                alert('Please select an account to assign this order to.');
                return;
            }

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('[name="_token"]').attr('content')
                }
            });

            $('#convertExternalModal .btn').prop('disabled', true);

            $.ajax({
                url: $("#convertExternalModal form.modal-body").prop('action'),
                type: 'post',
                dataType: 'json',
                data: {
                    'method' : method,
                    'account_id' : account
                },
                success: function (result) {

                },
                complete: function() {
                    if (method === 'reject') {
                        if ('referrer' in document) {
                            window.location = document.referrer;
                        } else {
                            window.history.back();
                        }
                    } else {
                        window.location = window.location;
                    }
                    //
                }
            });
        });
    }

    let upload_type = null;
    let upload_id = null;
    let dropZone = null;

    $('body').on('click', '.upload-files', function(e) {
        upload_type = $(this).data('type');
        upload_id = $(this).data('id');

        if (dropZone !== null) {
            dropZone.removeAllFiles(true);
        } else {

            dropZone = new Dropzone("#uploadModal #fileUploader", {
                url: "/documents/upload/" + upload_type + '/' + upload_id,
                dictResponseError: 'Error uploading file!',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                }
            });
        }

        $('#uploadModal .modal-title').text($(this).data('title') ?? '');

        $('#uploadModal').modal({
            backdrop: 'static',
            keyboard: false,
        }).modal('show');
    });

    $('body').on('hidden.bs.modal', '#uploadModal', function() {
        dropZone.removeAllFiles(true);

        console.log('need to update the contract modal now..')
        if (upload_type === 'contract') {
            getContract(upload_id, '#documents-line-tab');
        }

        upload_type = null;
        upload_id = null;
    });

    $('.new-contract').on('click', function() {
        $($(this).data('target')).modal('show');

        tinymce.init({
            selector: '#contractNotes',
            height: $('#contractNotes').data('height') ?? 150,
            menubar: false,
            statusbar: false,
            default_text_color: 'red',
            plugins: [
                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                'searchreplace wordcount visualblocks visualchars code fullscreen',
            ],
            toolbar1: 'undo redo | insert | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link | codesample',
            content_css: []
        });

    })

    $('body').on('change', '#salesOrderContractModal .select-account, #convertExternalModal .select-account', function () {
        let modal = $(this).parents('.modal');
        if (parseInt($(this).find('select').val()) === 1) {
            modal.find('.contact-information input').prop('readonly', false).val('');
            modal.find('.contact-information input[name="first_name"]')[0].focus();
            modal.find('.product-price').val('0').trigger('keyup');
            modal.find('tr.line-item input.product-discount, tr.line-item input.product-price, tr.line-item input.product-price-total').prop('readonly', true);
            modal.find('.link-new').hide();
            modal.find('.link-new').find('input').prop('checked', false);
            modal.find('span[data-alt][data-default]').each(function() {
               $(this).text($(this).data('alt'));
               $(this).parents('div').find('input').attr('placeholder', $(this).data('placeholder'));
            });

        } else {
            modal.find('.contact-information input').prop('readonly', true).val('');
            modal.find('tr.line-item input.product-discount, tr.line-item input.product-price, tr.line-item input.product-price-total').prop('readonly', false);
            if (parseInt($(this).find('select').val()) > 1) {
                modal.find('.link-new').fadeIn();
            }
            modal.find('.link-new').find('input').prop('checked', false);
            modal.find('span[data-alt][data-default]').each(function() {
                $(this).text($(this).data('default'));
            });
            let data = $(this).find('option:selected').data('params');
            if (data) {
                modal.find('.link-new').attr('data-bs-original-title', `Creates a new Zissou Account linked to ${data.xero_company}`);
                modal.find('.contact-information input').each(function () {
                    let name = $(this).attr('name');
                    $(this).val(data[name]);
                });
            }
        }
    }).trigger('change');

    $('body').on('click', '.contract-modal', function() {
        getContract($(this).data('contract'));
    });

    $('body').on('click', '.account-status', function() {
        if ($(this).hasClass('bg-primary')) {

            const elem = $(this);
            const account = $(this).parents('.account-row').data('account');

            $.ajax({
                url: `/accounts/${account}/archive`,
                type: 'get',
                dataType: 'json',
                success: function (result) {
                    if (result.result == 'success') {
                        elem.removeClass('bg-primary').addClass('bg-danger').text('Archived');
                    }
                }
            });
        }
    });

    $('body').on('click', '.validate-xero', function() {

        $(this).addClass('validating');

        if ($(this).hasClass('validate-all')) {

            $('table .validate-xero').addClass('validating');

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('[name="_token"]').attr('content')
                }
            });

            $.ajax({
                url: '/manage/xero/contacts/synchronize',
                type: 'post',
                dataType: 'json',
                success: function (result) {

                    console.log(result);
                    $('.validate-xero').removeClass('validating');

                    $('.account-row').each(function() {

                        let tenant = $(this).data('tenant');
                        let contact = $(this).data('contact');
                        let account = $(this).data('account');

                        if (tenant === "" || contact === '') {
                            $(this).addClass('row-error');
                            $(this).find('.validate-result').html(`Required data is missing for this Account.<pre>Account: ${account}\nTenant: ${tenant}\nContact: ${contact}`);
                        } else {
                            let filtered = result[tenant];
                            let found = false;

                            if (filtered) {

                                for (let i of filtered.result) {
                                    if (i.ContactID === contact) {
                                        $(this).addClass('row-ok');
                                        $(this).find('.validate-result').html(`<pre>Account: ${account}\nTenant: ${tenant}\nContact: ${contact}`);
                                        $(this).data('result', i);
                                        found = true;
                                        break;
                                    }
                                }

                                if (!found) {
                                    $(this).addClass('row-error');
                                    $(this).find('.validate-result').html(`Account could not be found in Xero.<pre>Account: ${account}\nTenant: ${tenant}\nContact: ${contact}`);
                                }
                            } else {
                                $(this).addClass('row-error');
                                $(this).find('.validate-result').html(`This Tenant is no longer available.<pre>Account: ${account}\nTenant: ${tenant}\nContact: ${contact}`);
                            }
                        }
                    })

                },
                error: function() {

                    console.error('It Broke');
                },
                done: function() {
                    $('.validate-xero').removeClass('validating');
                }
            });

        } else {
            let tenant = $(this).data('tenant');
            let contact = $(this).data('contact');
            let $this = $(this);

            if (typeof $(this).parents('.account-row').data('result') !== 'undefined') {
                console.log($(this).parents('.account-row').data('result'));
            } else {

                $.ajax({
                    url: '/manage/xero/contacts/' + tenant + '/' + contact,
                    type: 'get',
                    dataType: 'json',
                    data: {},
                    success: function (result) {
                        $this.parents('.account-row').data('result', result);
                        console.log($this.parents('.account-row').data('result'));
                    }
                });
            }
        }
    })

    $('.create-the-contract').on('click', function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('[name="_token"]').attr('content')
            }
        });

        if ($('#salesOrderContractModal select[name="account_id"]').val() === '') {
            alert('Please select an account to create this Order');
            return;
        }

        if ($('#salesOrderContractModal tr.line-item').length === 0) {
            alert('You need to add at least 1 product to create an Order');
            return;
        }

        tinyMCE.triggerSave(true,true);

        // Handle the new Reservation thingo //
        if ($('#salesOrderContractModal select[name="account_id"]').val() == 1) {

            let name = $('#salesOrderContractModal input[name="first_name"]').val();
            let email = $('#salesOrderContractModal input[name="email"]').val();
            let company = $('#salesOrderContractModal input[name="company"]').val();

            if (name === '' || email === '' || company === '') {
                alert('Please enter a First Name, an Email address and a Trading as value.');
                return;
            }
        }

        let $data = $("#salesOrderContractModal form.modal-body").serialize();

        $('#salesOrderContractModal .btn').prop('disabled', true);

        $.ajax({
            url: $("#salesOrderContractModal form.modal-body").prop('action'),
            type: 'post',
            dataType: 'json',
            data: $data,
            success: function (result) {
                $('[name="_token"]').attr('content', result.token);
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

                $('#salesOrderContractModal').modal('hide');
                $('#salesOrderContractModal .btn').prop('disabled', false);
                $('#salesOrderContractModal form')[0].reset();

                $('#contractModal .alert').remove();

                let output = '';
                if (result.message) {
                    output += `<div class="alert alert-success">
                        <p>${result.message}</p>
                    </div>`;
                }

                $('#contractModal .modal-title').html(result.contract_name);
                $('#contractModal .modal-body').html(output + result.contract);

                $('#contractModal').modal('show');

                feather.replace();

            },
            error: function (xhr, status, error) {
                console.log(xhr);
            }
        });

    });

    $('body').on('click', '.create-new-contact', function() {

        $('#xeroContactForm')[0].reset();

        let test_name = $('.select2-search__field[aria-controls="select2-select-account-results"]').val();
        if (test_name) {
            $('#xero-contact-name').val(test_name);
        }

        $('.account-select').select2('close');
        $('#newXeroContact').modal('show');
    });


    $('body').on('click', '.contact-modal', function(e) {
        e.preventDefault();

        let contact = parseInt($(this).data('contact'));

        $.ajax({
            url: '/contacts/ajax/' + contact,
            type: 'get',
            dataType: 'json',
            data: {},
            success: function (result) {

                tinymce.remove('#contactNoteTextarea');

                $('#contactModal .alert').remove();

                $('#contactModal .modal-title').html(result.contact_name);
                $('#contactModal .modal-body').html(result.contact);

                $('#contactModal').modal('show');
            }
        });
    })

    $('body').on('shown.bs.modal', '#contactModal', function() {

        $("#contactModal .log-output").scrollTop($("#contactModal .log-output").prop("scrollHeight"));

        $('#contactModal [data-bs-toggle="tooltip"]').tooltip();
        $('#contactModal .use-xero').on('click', function() {
            const target = $(this).next('input');
            target.val($(this).attr('data-bs-original-title'));
            $(this).fadeOut('fast');
        });

        $('#contactModal button.update-contact').on('click', function() {

            let $this = $(this);
            $(this).prop('disabled', true);

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                }
            });

            const form = $('#contactModal form.update-contact');
            $.post(form.attr('action'), form.serialize(), function(result) {

                $this.prop('disabled', false);

                $('[name="_token"]').attr('content', result.token);
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

            });
        });

        tinymce.init({
            selector: '#contactNoteTextarea',
            height: $("#contactNoteTextarea").data('height') ?? 150,
            menubar: false,
            statusbar: false,
            default_text_color: 'red',
            content_style: `
            body {
              background: #fff;
              font-size: 12px;
            }`,
            plugins: [
                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                'searchreplace wordcount visualblocks visualchars code fullscreen',
            ],
            toolbar1: 'undo redo | insert | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link | codesample',
            content_css: []
        });

        $('.account-data-table').DataTable({
            "paging": true,
            "aLengthMenu": [
                [3, 10, 25, 50, -1],
                [3, 10, 25, 50, "All"]
            ],
            "iDisplayLength": 3,
            "language": {
                search: ""
            },
            "drawCallback": function( settings ) {
                feather.replace();
            }
        });

        fix_data_tables('.account-data-table');
    })

    $('body').on('shown.bs.modal', '#salesOrderContractModal', function () {
        $('#salesOrderContractModal .contact-information input').prop('readonly', true).val('');
        $('#salesOrderContractModal').find('.link-new').hide();
        $('#salesOrderContractModal').find('.link-new').find('input').prop('checked', false);
        $('#salesOrderContractModal').find('span[data-alt][data-default]').each(function() {
            $(this).text($(this).data('default'));
        });

        $("#salesOrderContractModal .account-select").select2({
            dropdownParent: $('#salesOrderContractModal'),
            language: {
                noResults: function () {
                    return $("<span>No results found.. <a href='javascript:;' class='create-new-contact'>Do you want to create a Contact?</a></span>");
                }
            }
        });
    });

    $('body').on('shown.bs.modal', '#convertExternalModal', function () {
        $("#convertExternalModal .account-select").select2({
            dropdownParent: $('#convertExternalModal'),
            language: {
                noResults: function () {
                    return $("<span>No results found.. <a href='javascript:;' class='create-new-contact'>Do you want to create a Contact?</a></span>");
                }
            }
        });
    });

    $('body').on('show.bs.modal', '#salesOrderContractModal', function () {
        $('#salesOrderContractModal .modal-body')[0].reset();
        $('#salesOrderContractModal .account-select').val('').trigger('change');
        $('#salesOrderContractModal .contract-data-table tr.line-item').remove();
        $('#salesOrderContractModal .contract-data-table tr.empty-table').show();
    });

    $('body').on('click', '.create-order-from-contact', function(e) {
        let contact = $(this).data('contact');
        if ($('.new-contract').length > 0) {

            $('#salesOrderContractModal #select-account').val(contact).trigger('change');
            $('#contactModal').modal('hide');

            $('.new-contract').trigger('click');
        }
    });

    $('body').on('click', '.send-account-note', function () {

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $(this).prop('disabled', true);

        let $data = {
            'account_id' : $(this).data('contact'),
            'note' : tinymce.get("contactNoteTextarea").getContent(),
        };

        $.ajax({
            url: "/engagements/cnote",
            type: 'post',
            dataType: 'json',
            data: $data,
            success: function (result) {

                tinymce.get("contactNoteTextarea").setContent("");

                $('[name="_token"]').attr('content', result.token);
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result.token
                    }
                });

                $('.send-account-note').prop('disabled', false);
                $('#contactModal ul.chat-list').append(result.content);

                $("#contactModal .log-output").scrollTop($("#contactModal .log-output").prop("scrollHeight"));
            },
            error: function (xhr, status, error) {
                console.log(xhr);
            }
        });
    });

    $('body').on('click', 'td.clickable', function(e) {
        const button = $(this).parents('tr').find('.btn-dark');
        if (button && e.target.nodeName !== 'A' && e.target.nodeName !== 'BUTTON') {
            button[0].click();
        }
    })

    $('button.edition-months').on('click', function() {

        month_target = $(this);

        let edition = $(this).data('edition');

        // Reset Everything //
        $('#orderProductMonthSelection .set-months')[0].reset();
        $('#orderProductMonthSelection .tab-pane').removeClass(['show','active']);

        $('.edition-wrapper').show();

        if (edition !== '') {
            $('.edition-wrapper').not('[data-edition="' + edition + '"]').hide();
        }

        let publication = $(this).data('publication');
        $('#orderProductMonthSelection .tab-pane#v-' + publication).addClass(['show','active']);

        // Need to re-check everything if it was stored previously //'
        let months = 0;
        month_target.parent().find('.edition-months-selected').each(function() {
            let checked = $(this).val().split(',');
            for (let i of checked) {
                $('#orderProductMonthSelection .tab-pane.active.show input[data-edition="' + $(this).data('edition') + '"][value="' + i + '"].is-month').prop('checked', true);
                months++;
            }
        });

        $('#orderProductMonthSelection .modal-footer h6 span').text(months);
        $('#orderProductMonthSelection').modal('show');

    });

    $('#orderProductMonthSelection input.select-all').on('click', function() {
        $(this).parents('.edition-card').find('.is-month').prop('checked', $(this).is(':checked'));
    });

    $('#orderProductMonthSelection input[type="checkbox"].is-month').on('change', function() {
        let count = $('#orderProductMonthSelection .tab-pane.active.show input.is-month:checked').length;

        let ed_total = $(this).parents('.edition-card').find('.is-month').length;
        let ed_count = $(this).parents('.edition-card').find('.is-month:checked').length;
        $(this).parents('.edition-card').find('.select-all').prop('checked', ed_total === ed_count);

        $('#orderProductMonthSelection .modal-footer h6 span').text(count);
    });

    $('#orderProductMonthSelection button.add-months').on('click', function() {
        let checked = 0;
        let publication = month_target.data('publication');

         month_target.parent().find('.edition-months-selected').val('');

        $('#orderProductMonthSelection .tab-pane.active.show input.is-month:checked').each(function() {
            let edition = $(this).data('edition');
            let field = month_target.parent().find('.edition-months-selected[data-edition="' + edition + '"]');
            field.val((field.val() === '' ? '' : field.val() + ',') + $(this).val());
            checked++;
        });

        month_target.text(checked === 0 ? 'Select Month(s)' : checked + ' selected');

        if (checked > 0 && !month_target.parents('tr').find('input.will-add').is(':checked')) {
            month_target.parents('tr').find('input.will-add').prop('checked', true).trigger('change');
        } else if (checked === 0) {
            month_target.parents('tr').find('input.will-add').prop('checked', false).trigger('change');
        }

        month_target = null;

        $('#orderProductMonthSelection').modal('hide');
    });
    /* END of Month Handlers */


    let update_table_price = function () {
        let sub = 0, gst = 0, total = 0;
        $('table.generated-products-table input.product-price').each(function () {
            if (!$(this).parents('tr').hasClass('deleted')) {

                let row_sub = $(this).val(), row_gst = row_sub * .1, row_total = row_sub * 1.1;

                $(this).parent('tr').find('.product-price-gst').val(row_gst);
                $(this).parent('tr').find('.product-price-total').val(row_total);

                sub += parseFloat(row_sub);
                gst += parseFloat(row_gst);
                total += parseFloat(row_total);
            }
        });
        $('table.generated-products-table .table-total').val(total.toFixed(2));
        $('table.generated-products-table .table-total').val(total.toFixed(2));
        $('table.generated-products-table .table-total').val(total.toFixed(2));
    };

    update_table_price();

    let add_to_table = function (data, pack) {

        let count = $('.generated-products-table tr').length;

        let input = $('.generated-products-table').hasClass('sales-order-table')
            ? `<input type="hidden" name="items[${count}][product_id]" value="${data.id}"><input type="number" class="form-control text-right product-price" name="items[${count}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${data.price ?? data.msrp}">`
            : `<input type="number" class="form-control text-right product-price" name="products[${data.id}][product_price]" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" value="${data.price ?? data.msrp}">`;

        if ($('.generated-products-table').hasClass('sales-order-table') && pack) {
            input += `<input type="hidden" name="items[${count}][package_data]" value="${JSON.stringify(pack).replace(/"/g, '\&quot;')}">`
        }

        $('.generated-products-table tbody').append(`<tr>
            <td>${data.id}</td>
            <td>${data.name}</td>
            <td>${data.publication}</td>
            <td class="text-right">${data.msrp}</td>
            <td class="text-right">${input}</td>
            <td width="100">
                <div class="btn-group">
                    <a href="/products/${data.slug}/edit/"
                       class="btn btn-primary btn-icon btn-sm">
                        <i class="link-arrow" data-feather="edit-3"></i>
                    </a>
                    <button type="submit" class="btn btn-danger btn-icon btn-sm">
                        <i class="link-arrow" data-feather="trash-2"></i>
                    </button>
                </div>
            </td>
        </tr>`);

        feather.replace();
        update_table_price();
    };

    $('body').on('submit', 'form.confirm-delete', function (e) {
        return confirm('Are you sure you want to ' + ($(this).data('action') ? $(this).data('action') : 'delete') + ' this ' + $(this).data('type') + '?');
    });

    $('body').on('keyup blur', '.product-price', function (e) {
        $(this).parents('tr.line-item').find('.product-discount').val(0);
        update_table_price();
    });

    $('body').on('click', '.generated-products-table .btn-danger', function () {
        if (confirm('Are you sure you want to remove this product?')) {
            let tr = $(this).parents('tr');
            if (tr.hasClass('soft-delete')) {
                tr.find('input.soft-delete').val(1);
                tr.addClass('deleted').hide();
            } else {
                $(this).parents('tr').remove();
            }

            update_table_price();
        }
    });

    $('button.add-to-table').on('click', function () {
        let selected = $('select.add-to-table').val();
        if (selected) {
            let data = $('select.add-to-table option[value="' + selected + '"]').data('params');
            if (!data) {
                let id = selected.split('-')[0];
                $.get('/query/products/byPackage/' + id, function (data) {
                    for (let product of data.products) {
                        add_to_table({
                            id: product.id,
                            name: product.name,
                            publication: product.publication.name,
                            msrp: product.msrp,
                            price: product.pivot.product_price,
                            slug: product.slug
                        }, data.package);
                    }
                }, 'json');
            } else {
                add_to_table(data);
            }

            $('select.add-to-table').val('').trigger('change');
        }
    });

    $('body').on('keyup blur', '.generates-uri', function (e) {

        let target = $($(this).data('target'));

        let value = $(this).val();
        if (target && !$(this).hasClass('display-only')) {

            if (value == '') {
                target.val('');
                target.parent().fadeOut('fast');
            } else {
                let clean = value.replace(/(^\-+|[^a-zA-Z0-9\/_| -]+|\-+$)/g, '').toLowerCase().replace(/[\/_| -]+/g, '-');
                target.parent().fadeIn('fast');
                target.val('/' + clean + '/');
            }
        } else {
            target.parent().fadeIn('fast');
        }

        //
        $('.stealth-input input').each(function () {
            $(this).css('width', $(this).val().length + "ch");
        });
    });

    $('.generates-uri').trigger('keyup');

    $('.box-creator .has-create').on('change', function () {
        if ($(this).val() == -1) {
            $(this).parent().find('.is-create').fadeIn('fast', function () {
                $(this).addClass('active');
            });
            $(this).parent().find('.is-create input').focus();
        }
    });

    $('.box-creator .is-create button').on('click', function (e) {
        $(this).parents('.is-create').fadeOut('fast')
        $(this).parents('.is-create').find('input').val('');
        let select = $(this).parents('.box-creator').find('select');
        select.val(select.find('option:first').val());
    });

    // Readonly thingo //
    $('select.apply-readonly').on('change', function () {
        let values = $(this).data('when').split(',');
        let target = $(this).data('target');
        $(target).prop('readonly', values.indexOf($(this).val()) >= 0);
    });

    if ($('#genericDatePicker').length > 0) {
        $('#genericDatePicker').datepicker({
            format: "dd/mm/yyyy",
            autoclose: true
        });
    }

    if ($('#edition-duration').length > 0) {
        $('#edition-duration').datepicker({
            format: "MM yyyy",
            startView: 1,
            minViewMode: 1
        }).on('changeDate', function(e) {
            $('#edition-duration').removeClass('auto-filled');
        })

        $('select[name="previous"]').on('change', function() {

            let at = $('#edition-duration input[name="current_at"]');
            let until = $('#edition-duration input[name="current_until"]')

            if (at.val() == '' && until.val() == '') {
                at.val($(this).find('option:selected').data('next-at'));
                until.val($(this).find('option:selected').data('next-until'));
                $('#edition-duration').addClass('auto-filled');
            } else {
                if ($('#edition-duration').hasClass('auto-filled')) {
                    at.val($(this).find('option:selected').data('next-at'));
                    until.val($(this).find('option:selected').data('next-until'));
                }
            }
        })
    }

    $('.update-fields').on('blur', function () {
        let $this = $(this);
        let target = $($(this).data('target'));
        if (target) {
            target.each(function () {
                if ($(this).val() === '' || $(this).val() === $this.data('last')) {
                    $(this).val($this.val());
                }
            });
        }

        $this.data('last', $this.val());
    });

    $('.prices-table .toggle-prices').on('change', function () {
        $('.update-fields').trigger('blur');
    });

    $('.set-type').on('change blur', function () {
        let product_type = $(this).val();
        $('#set-type').html('&nbsp;&nbsp;' + (product_type === 'print' ? 'mm' : 'px') + '&nbsp;&nbsp;');

        $(`.set-weight option`).hide();
        $(`.set-weight option.${product_type}-weight`).show();
        $($(`.set-weight option.${product_type}-weight`)[0]).prop('selected', true);
    })

    $('body').on('click', '.add-loading', function () {

        if (!$('.form-loading').hasClass('is-loading')) {
            let i = $('.form-loading tbody tr').length;
            let $row = $('.form-loading tfoot tr').clone();
            $row.find('input.row-id').attr('name', 'loading[' + i + '][id]');
            $row.find('input.is-deleted').attr('name', 'loading[' + i + '][deleted]').val(0);
            $row.find('input[type="text"]').attr('name', 'loading[' + i + '][name]');
            $row.find('input[type="number"]').attr('name', 'loading[' + i + '][rate]');
            $('.form-loading tbody').append($row);

            $('.form-loading').scrollTop($('.form-loading')[0].scrollHeight);
        }
    });

    $('body').on('click', '.view-loading', function () {
        $('#loadingModal').modal('show');
    });

    $('body').on('click', '.remove-loading', function () {
        $(this).parents('tr').hide();
        $(this).parents('tr').find('.is-deleted').val(1);
    });

    $('body').on('click', '.save-loading', function () {

        $('#loadingModal .modal-footer button').prop('disabled', true);
        $('.form-loading').addClass('is-loading');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
            }
        });

        $.post('/manage/loading', $('.form-loading').serialize(), function (data) {
            $('#loadingModal .modal-footer button').prop('disabled', false);
            $('.form-loading').removeClass('is-loading');
            $('.form-loading table > tbody').html(data.content);
            feather.replace();

            $('meta[name="csrf-token"]').attr('content', data.csrf);
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': data.csrf
                }
            });
        }, 'json');
    });

    $('body').on('click', '.hidden-row-link', function () {
        $(this).hide();
        $(this).next().show();
    });

    $('body').on('click', '.new-publication-link', function() {
        $('#newPublication').modal('show');
    });

    $('#newPublication .btn-primary').on('click', function() {
        let pub = $('#newPublication form input[name="publication_name"]');
        if (pub.val() == '') {
            $('#newPublication form input[name="publication_name"]').focus();
        } else {

            $.ajax({
                url: $('#newPublication form').data('action'),
                type: 'post',
                dataType: 'json',
                data: $('#newPublication form').serializeArray(),
                success: function (result) {
                    $('meta[name="csrf-token"]').attr('content', result.token);
                    $.ajaxSetup({
                        headers: {
                            'X-CSRF-TOKEN': result.token
                        }
                    });
                },
                error: function (xhr, status, error) {
                    console.log(xhr);
                }
            });
        }
    });

    $('.show-file button').on('click', function() {
        $(this).parents('.has-file').removeClass('has-file');
        $(this).parents('.show-file').remove();
    });

    $('.input-clickable').on('click', function() {
        window.open($(this).data('link'));
    })

});